<template>
  <div class="funnel-report-container">
    <FunnelSearch @handleAutoSearch="handleAutoSearch" />
    <FunnelBox :query="query" />
  </div>
</template>

<script>
import FunnelSearch from './FunnelSearch'
import FunnelBox from './FunnelBox'
export default {
  name: 'FunnelReport',
  components: { FunnelSearch, FunnelBox },
  data () {
    return {
      query: {}
    }
  },
  methods: {
    handleAutoSearch (val) {
      this.query = JSON.parse(JSON.stringify(val))
    }
  }
}
</script>

<style lang="less">
.funnel-report-container {
  margin: 0 10px 10px 10px;
}
</style>
