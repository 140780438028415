<template>
  <div class="funnel-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
      <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
    </a-range-picker>
    <m-select-more
      class="search_item"
      v-model="searchquery.appIdList"
      :allData="appList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="应用"
      @change="changeAppIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.groupIdList"
      :allData="groupList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="流量分组"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.channelIdList"
      :allData="channelList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="渠道"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <m-select-more
      class="search_item"
      v-model="searchquery.positionIdList"
      :allData="positionList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告样式"
      :showSelectedPart="true"
      width="450px"
      :canSelectAll="true"
      @change="handleAutoSearch"
    />
    <div class="search_item input">
      <a-input placeholder="请输入SDK版本号" allowClear v-model.trim="searchquery.sdkVersion" @input="changeInputValue" />
    </div>
    <div class="search_item input">
      <a-input placeholder="请输入应用版本" allowClear v-model.trim="searchquery.appVersion" @input="changeInputValue" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appIdList: [],
        placeIdList: [],
        groupIdList: [],
        channelIdList: [],
        positionIdList: [],
        sdkVersion: '',
        appVersion: ''
      },
      timer: null
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.getAppList()
    this.getPlaceList()
    this.getGroupList()
    this.getChannelList()
    this.getPositionList()
    // 进入时搜索
    this.handleAutoSearch()
  },
  methods: {
    changeInputValue () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      if (ids.length === 0) {
        // 广告位无值，通过全部placelist查询group列表
        // await this.groupListByAdplace(this.placeList.map((item) => item.id))
        await this.getGroupList()
      } else {
        // 广告位选了，通过选中的place查询group列表
        await this.groupListByAdplace(ids)
      }
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.funnel-search-container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .search_item {
    padding: 10px 5px;
    width: 220px;
    display: inline-block;
    &.input {
      padding: 10px 5px;
    }
  }
}
</style>
